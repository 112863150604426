import React, { useState, Fragment } from 'react';
import { z } from 'zod';
import { Avatar, Button, Dialog, DialogTitle, DialogContent, Stack, Switch, Typography } from '@mui/material';

import { findModuleScoreDimensionSettings } from '../Module/ModuleDimensionSettings';
import { findCategoryScoreDimensionSettings } from '../Category/CategoryScoreDimensionSettings';

import { ModuleInterface, moduleTypeSchema } from '../Module/Module';
import { Category } from '../Category/Category';
import { Controller, useFormContext } from 'react-hook-form';
import { gql, useQuery } from '@apollo/client';
import useWorkspace from '../Workspace/useWorkspace';
import { WorkspaceInterface } from '../Workspace/WorkspaceInterface';

export const activeModulesSchema = z.record(moduleTypeSchema, z.boolean());
export type ActiveModules = z.infer<typeof activeModulesSchema>;

export const useModuleSettings = (categories: Category[]) => {
  const modules = categories?.flatMap(category => category.modules) || [];
  const activeModules: ActiveModules = modules.reduce((modules, module) => ({
    ...modules,
    [module.type]: module.active || false,
  }), {});
  return { activeModules };
};

interface WorkspaceModuleSettingsProps {
  categories: Category[];
  interactive?: boolean;
}

export const ModuleSettingsFormContent = ({ categories }: { categories: Category[] }) => {
  return (
    <Fragment>
      <Typography fontSize={18} fontWeight="bold">
        Select at least one assessment for your workspace
      </Typography>

      {categories.map(category => (
        <CategoryModuleSettings key={category.id} category={category} interactive={true} />
      ))}
    </Fragment>
  );
};

const WorkspaceModuleSettings = ({ categories, interactive }: WorkspaceModuleSettingsProps) => {
  const { workspace } = useWorkspace();
  return (
    <Stack spacing={2}>
      {categories.map(category => (
        <CategoryModuleSettings key={category.id} workspace={workspace} category={category} interactive={interactive} />
      ))}
    </Stack>
  );
};

const CategoryModuleSettings = ({ workspace, category, interactive = false }: {
  workspace?: WorkspaceInterface, // does not exist during initial workspace creation
  category: Category;
  interactive?: boolean
}) => {
  return (
    <Stack key={category.id} spacing={2}>
      <Typography fontSize={14} fontWeight="bold">
        {category.name}
      </Typography>
      {category.modules.map(module => {
        return <ModuleSettings key={module.id} workspace={workspace} category={category} module={module}
                               interactive={interactive} />;
      })}
    </Stack>
  );
};

interface ModuleSettingsProps {
  workspace?: WorkspaceInterface; // does not exist during initial workspace creation
  category: Category;
  module: ModuleInterface;
  updateModule?: () => void;
  interactive: boolean;
}

const ModuleSettings = ({ workspace, category, module, interactive = false }: ModuleSettingsProps) => {
  const formContext = useFormContext();
  const control = formContext?.control;

  const ModuleDimensionSettings = findModuleScoreDimensionSettings(module.type);
  const categoryComponentSettings = findCategoryScoreDimensionSettings(category.type);

  const icon = ModuleDimensionSettings.icon;

  const [detailsOpen, setDetailsOpen] = useState(false);

  return (
    <Stack className="module-settings" key={module.id} direction="row" alignItems="center" spacing={2}>
      <Avatar variant="rounded" sx={{ bgcolor: categoryComponentSettings.color }}>
        {React.createElement(icon, { sx: { color: '#000' } })}
      </Avatar>

      <Stack flex="1" fontSize={14} direction="column">
        <Typography>{module.name}</Typography>
        <Typography variant="body2">{module.summary}</Typography>
      </Stack>

      {interactive && workspace &&
        <>
          <Button onClick={() => setDetailsOpen(true)} variant="text" size="small">View Questions</Button>
          <AssessmentDetailsDialog workspace={workspace} category={category} module={module} open={detailsOpen}
                                   onClose={() => setDetailsOpen(false)} />
        </>
      }

      {interactive ? (
        <Controller
          name={`activeModules.${module.type}`}
          control={control}
          render={({ field }) => {
            return <Switch {...field} type="checkbox" checked={field.value} />;
          }}
        />
      ) : (
        <Switch disabled checked={module.active} />
      )}
    </Stack>
  );
};

export default WorkspaceModuleSettings;

type AssessmentDetailsDialogProps = {
  workspace: WorkspaceInterface, category: Category, module: ModuleInterface, open: boolean, onClose: () => void
}

type ModuleQuestionsQueryData = {
  workspace: WorkspaceInterface;
}

const AssessmentDetailsDialog = ({ workspace, category, module, open, onClose }: AssessmentDetailsDialogProps) => {
  const { data, loading, error } = useQuery<ModuleQuestionsQueryData>(MODULE_QUESTIONS_QUERY, {
    variables: { workspaceId: workspace.id, categoryType: category.type, moduleType: module.type },
    skip: !open,
  });

  if (!data) {
    return null;
  }

  const questions = data.workspace.categories[0].modules[0].questions;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Questions for {module.name}</DialogTitle>
      <DialogContent>
        {loading && <Typography>Loading questions...</Typography>}
        {error && <Typography>Error loading questions: {error.message}</Typography>}
        {questions.length > 0 ? (
          <Stack spacing={2}>
            {questions.map((question) => (
              <Typography key={question.id}>{question.text}</Typography>
            ))}
          </Stack>
        ) : (
          <Typography>No questions available for this module.</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};


export const MODULE_QUESTIONS_QUERY = gql`
  query ModuleQuestions($workspaceId: ID!, $categoryType: CategoryType, $moduleType: ModuleType) {
    workspace(id: $workspaceId) {
      id
      categories(type: $categoryType) {
        id
        modules(type: $moduleType) {
          type
          questions {
            id
            text
          }
        }
      }
    }
  }
`;

