import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { styled } from '@mui/material/styles';
import cloneDeep from 'lodash.clonedeep';
import PageStyle from '../UI/PageStyle';
import ConfidentialitySettings from '../Account/ConfidentialitySettings';
import Integrations from '../Account/Integrations';
import SurveySettings from '../Account/SurveySettings';
import { useWorkspace } from '../Workspace/useWorkspace';
import AccountWorkspaceSettings from './AccountWorkspaceSettings';
import useAdminRoute from '../User/useAdminRoute';
import AssessmentSettings from './AssessmentSettings';

const AccountSettings = () => {
  useAdminRoute();
  const { workspace } = useWorkspace();
  const { id } = workspace;

  const { data, refetch } = useQuery(WORKSPACE_SETTINGS_QUERY, { variables: { id: id } });

  if (!data) {
    return null;
  }

  const workspaceSettings = cloneDeep(data.workspace);

  return (
    <StyledAdmin>
      <PageStyle design='standard'>
        <AccountWorkspaceSettings refetch={refetch} />
        <ConfidentialitySettings workspaceSettings={workspaceSettings} />
        <Integrations />
        <SurveySettings workspaceSettings={workspaceSettings} />
      </PageStyle>
    </StyledAdmin>
  );
};

const StyledAdmin = styled('div')`
  .MuiTableCell-root:first-of-type {
    width: 33%;
  }
  .MuiChip-root {
    margin-left: 10px;
  }
  .MuiButton-root {
    min-width: inherit;
  }
  .icon-svg {
    width: 100%;
    height: 100%;
  }
  .MuiSwitch-root {
    margin-left: -12px;
  }
`;

const WORKSPACE_SETTINGS_QUERY = gql`
  query WorkspaceSettings($id: ID!) {
    workspace(id: $id) {
      id
      name
      company {
        id
        imageUrl
        domain
      }

      categories {
        id
        name
        type
        confidentialityThreshold
      }

      surveySchedule {
        cron
      }
    }
  }
`;

export default AccountSettings;
