import React from 'react';
import { gql, useQuery } from '@apollo/client';
import cloneDeep from 'lodash.clonedeep';
import PageStyle from '../UI/PageStyle';
import AssessmentSettings from './AssessmentSettings';
import { useWorkspace } from '../Workspace/useWorkspace';
import useAdminRoute from '../User/useAdminRoute';

const Assessments = () => {
  useAdminRoute();
  const { workspace } = useWorkspace();
  const { id } = workspace;

  const { data, refetch } = useQuery(WORKSPACE_SETTINGS_QUERY, { variables: { id: id } });

  if (!data) {
    return null;
  }

  const workspaceSettings = cloneDeep(data.workspace);

  return (
    <PageStyle design='standard'>
      <AssessmentSettings workspaceSettings={workspaceSettings} refetch={refetch} />
    </PageStyle>
  );
};

const WORKSPACE_SETTINGS_QUERY = gql`
  query WorkspaceSettings($id: ID!) {
    workspace(id: $id) {
      id
      name
      categories {
        id
        name
        type
        confidentialityThreshold
        modules(states: [Active, Inactive]) {
          id
          name
          type
          active
          summary
          description
          ... on EngagementModule {
            confidentialityThreshold
          }
        }
      }
    }
  }
`;

export default Assessments;
