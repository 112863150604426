import React from 'react';
import { Avatar } from '@mui/material';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import HiveRoundedIcon from '@mui/icons-material/HiveRounded';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';

import { useWorkspace } from '../Workspace/useWorkspace';
import { useMatches } from 'react-router';
import WaterDropRoundedIcon from '../Icons/WaterDropRoundedIcon';
import useActiveFeatures from '../Module/useActiveFeatures';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import useAuthenticatedUser from '../User/useAuthenticatedUser';
import { HYVE_YELLOW } from '../Theme/AppTheme';

export type NavigationItem = {
  key: NavigationKey;
  name: string;
  icon: React.ReactElement;
  ref?: React.RefObject<HTMLDivElement | null>;
  onClick?: () => void;
};

export type PrimaryNavigationArea = NavigationItem & {
  label?: string;
  mediumLabel?: string;
  path?: string;
  badge?: boolean;
  active?: boolean;
};

export type PrimaryNavigationAreas = { [key: string]: PrimaryNavigationArea };

export type NavigationKey =
  | 'workspace'
  | 'intelligence'
  | 'clients'
  | 'projects'
  | 'employee-experience'
  | 'company'
  | 'teams'
  | 'ai'
  | 'help'
  | 'invite'
  | 'account';

type MatchHandle = { area: string | undefined };

const useActiveAreaName = () => {
  const matches = useMatches();
  const areaMatch = matches.find(match => {
    const handle = match.handle as MatchHandle;
    return handle?.area;
  });

  const handle = areaMatch?.handle as MatchHandle;
  return handle.area || null;
};

export const usePrimaryNavigation = () => {
  const { workspace } = useWorkspace();
  const { user } = useAuthenticatedUser();
  const { avatar } = user;
  //  const avatar = 'https://uploads.hyve.app/public/production/8d84d6faaf553c1b71b120c25aebe16e.png';

  const activeAreaName = useActiveAreaName();
  const { aiActive, clientsActive, employeeExperienceActive, companyActive, projectsActive, teamsActive } = useActiveFeatures();

  if (!workspace) {
    throw new Error('Workspace undefined');
  }

  const areas: PrimaryNavigationAreas = {};
  const isActive = (areaName: string) => areaName === activeAreaName;
  const areaColor = (areaName: string) => (isActive(areaName) ? HYVE_YELLOW : '#fff');

  areas.workspace = {
    key: 'workspace',
    name: 'Workspace',
    label: 'Dashboard',
    path: `/workspace/${workspace.id}`,
    icon: <DashboardRoundedIcon sx={{ color: areaColor('Workspace') }} />,
  };

  areas.intelligence = {
    key: 'intelligence',
    name: 'Intelligence',
    label: 'Intelligence',
    badge: workspace.needsMyIntelligence,
    path: `/workspace/${workspace.id}/intelligence`,
    icon: <WaterDropRoundedIcon sx={{ color: areaColor('Intelligence') }} />,
  };

  if (clientsActive) {
    areas.clients = {
      key: 'clients',
      name: 'Clients',
      label: 'Clients',
      path: `/workspace/${workspace.id}/clients`,
      icon: <CorporateFareRoundedIcon sx={{ color: areaColor('Clients') }} />,
    };
  }

  if (projectsActive) {
    areas.projects = {
      key: 'projects',
      name: 'Projects',
      label: 'Projects',
      path: `/workspace/${workspace.id}/projects`,
      icon: <HiveRoundedIcon sx={{ color: areaColor('Projects') }} />,
    };
  }

  if (employeeExperienceActive) {
    areas['employee-experience'] = {
      key: 'employee-experience',
      name: 'Employee Experience',
      label: 'Employee Experience',
      path: `/workspace/${workspace.id}/employee-experience`,
      icon: <AutoAwesomeRoundedIcon sx={{ color: areaColor('Employee Experience') }} />,
    };
  }

  if (companyActive) {
    areas.company = {
      key: 'company',
      name: 'Company',
      label: 'Company',
      path: `/workspace/${workspace.id}/company`,
      icon: <ApartmentRoundedIcon sx={{ color: areaColor('Company') }} />,
    };
  }

  if (teamsActive) {
    areas.teams = {
      key: 'teams',
      name: 'Teams',
      label: 'Teams',
      path: `/workspace/${workspace.id}/teams`,
      icon: <SupervisedUserCircleRoundedIcon sx={{ color: areaColor('Teams') }} />,
    };
  }

  if (aiActive) {
    areas.ai = {
      key: 'ai',
      name: 'Hyve AI',
      label: 'Hyve AI',
      mediumLabel: 'Hyve AI',
      icon: <BoltRoundedIcon sx={{ color: areaColor('Hyve AI') }} />,
    };
  }

  areas.help = {
    key: 'help',
    name: 'Help',
    label: 'Help',
    mediumLabel: 'Help',
    icon: <HelpRoundedIcon sx={{ color: areaColor('Help') }} />,
  };

  const accountIcon = <AccountCircleRoundedIcon sx={{ color: areaColor('Account'), fontSize: 28 }} />;

  areas.account = {
    key: 'account',
    name: 'Account',
    label: 'Account',
    mediumLabel: 'Account',
    path: `/workspace/${workspace.id}/account/profile`,
    icon: (
      <Avatar
        src={avatar}
        alt='User Avatar'
        sx={{
          backgroundColor: '#000',
          width: 24,
          height: 24,
          boxShadow: isActive('Account') ? `0 0 0 3px ${HYVE_YELLOW}` : 'none',
        }}
      >
        {accountIcon}
      </Avatar>
    ),
  };

  const activeArea = Object.values(areas).find(area => area.name === activeAreaName);

  return { areas, activeArea, activeAreaName };
};
